import { createSelector } from '@reduxjs/toolkit';
import { PaymentTypeOption, PAYMENT_TYPES } from 'types/Order';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { RootState } from 'store/store';
import { formatPrice } from 'helpers';
import { PromoProduct } from 'types/Promotion';
import { selectMenuData } from './menuSelectors';
import { selectPromotionsData } from './promotionSelectors';

const selectOrderData = (state: RootState) => state.order;
/**
 * Returns the basket
 */
export const selectBasket = createSelector(
  [selectOrderData],
  (orderData) => orderData.basket
);
/**
 * Returns calculate basket isLoading
 */
export const selectCalculateBasketIsLoading = createSelector(
  [selectOrderData],
  (orderData) => orderData.calculatedBasketIsLoading
);
/**
 * Returns the basket after calculation
 */
export const selectCalculatedBasket = createSelector(
  [selectOrderData],
  (orderData) => orderData.calculatedBasket
);
/**
 * Returns order data
 */
export const selectOrder = createSelector(
  [selectOrderData],
  (orderData) => orderData.order
);
/**
 * Returns order history
 */
export const selectOrderHistory = createSelector(
  [selectOrderData],
  (orderData) => orderData.orderHistory
);
/**
 * Returns order check delivery data
 */
export const selectCheckDelivery = createSelector(
  [selectOrderData],
  (orderData) => orderData.checkDelivery
);
/**
 * Returs checkDelivery isLoading
 */
export const selectCheckDelieryIsLoading = createSelector(
  [selectOrderData],
  (orderData) => orderData.checkDeliveryIsLoading
);
/**
 * Returs order isLoading
 */
export const selectOrderIsLoading = createSelector(
  [selectOrderData],
  (orderData) => orderData.orderIsLoading
);
/**
 * Returns order payment type.
 */
export const selectOrderPaymentType = createSelector(
  [selectOrder],
  (order) => order.payment?.payment_type
);
/**
 * Returns calculated basket items
 */
export const selectCalculatedBasketItems = createSelector(
  [selectOrderData],
  (orderData) => orderData.calculatedBasket?.data?.basket_items
);
/**
 * Returns calculated basket active promo
 */
export const selectActivePromo = (id: number) =>
  createSelector([selectOrderData], (orderData) =>
    orderData.order.actions.some((item) => item.action_id === id)
  );
/**
 * Returns converted data for request available bonuses
 */
export const selectBasketItemsForAvailableBonuses = createSelector(
  [selectOrderData],
  (orderData) =>
    orderData.basket.map((item) => {
      return {
        id: item.id,
        cooking_option_id: item.cooking_option_id,
        count: item.count,
        ingrs: item.ingredients,
        group_modifiers: item.group_modifiers,
      };
    })
);
/**
 * Returns the id for deleting an item from the basket
 */
export const selectBasketId = (id: number) =>
  createSelector(
    [selectBasket],
    (basket) => basket.find((item) => item.id === id)?.basketId
  );

export const selectCalculatedBasketResponse = createSelector(
  [selectCalculatedBasket],
  (calculatedBasket) => {
    return {
      success: calculatedBasket.success,
      message: calculatedBasket.message,
    };
  }
);
/**
 * Returns message and is success create order
 */
export const selectOrderResponse = createSelector(
  [selectOrderData],
  (orderData) => orderData.orderResponse
);
/**
 * Returns the basket recommendation
 */
export const selectBasketRecommendations = createSelector(
  [selectMenuData],
  (menuData) =>
    menuData
      ?.map((category: { items: any[] }) =>
        category.items.filter((item) => item.is_addorder_list && item.is_active)
      )
      .flat()
);
/**
 * Returns only active orders
 */
export const selectActiveOrdersData = createSelector(
  [selectOrderHistory],
  (orderHistory) => {
    const activeOrders = orderHistory.filter(
      (order) => order.status !== 3 && order.status !== 10
    );
    return activeOrders;
  }
);
/**
 * Returns the cutlery
 */
export const selectCutlery = createSelector(
  [selectOrderData],
  (orderData) => orderData.order.cutlery
);
/**
 * Returns a variable for delivery (boolean)
 */
export const selectIsDelivery = createSelector(
  [selectOrder],
  (order) => order.delivery_type === 0
);
/**
 * Returns order promotions
 */
export const selectOrderPromotions = createSelector(
  [selectOrder],
  (order) => order.actions
);
/**
 * Returns a variable to display an empty basket
 */
export const selectIsEmptyBasket = createSelector(
  [selectBasket, selectCalculatedBasket],
  (basket, calculatedBasket) => {
    const basketLength = basket.length;

    if (calculatedBasket.success === false) {
      return basketLength <= 0;
    }

    const promoDishesLength = calculatedBasket?.data?.presents.length;
    const basketItemsLength = calculatedBasket?.data?.basket_items.length;
    return promoDishesLength + basketItemsLength <= 0 || basketLength <= 0;
  }
);
/**
 * Returns length of basket array with promo dishes
 */
export const selectBasketLength = createSelector(
  [selectBasket, selectCalculatedBasket],
  (basket, calculatedBasket) => {
    const basketLength = basket.length;

    if (calculatedBasket.success === false) {
      return basketLength;
    }

    const promoDishesLength = calculatedBasket?.data?.presents.length;
    const basketItemsLength = calculatedBasket?.data?.basket_items.length;
    return promoDishesLength + basketItemsLength || basketLength;
    // return basketItemsLength || basketLength;
  }
);
/**
 * Returns the count of the selected item in the basket
 */
export const selectProductCount = (optionId: number) =>
  createSelector(
    [selectOrderData],
    (orderData) =>
      orderData.basket.find((product) => product.id === optionId)?.count
  );
/**
 * Returns product data for the promo to display in the list of products
 */
export const selectDataDispalyPresents = createSelector(
  [selectCalculatedBasket, selectPromotionsData],
  (calculatedBasket, promotionsData) => {
    if (calculatedBasket.success === false) {
      return [];
    }

    const presentActionId = calculatedBasket?.data?.presents[0]?.action;
    const presentItemOption = calculatedBasket?.data?.presents[0]?.item_option;

    const currentPromo = promotionsData.find(
      (promo: { id: any }) => promo.id === presentActionId
    );

    const promoDish = currentPromo?.promo_dishes.filter(
      (item: { item_option: any }) => item.item_option === presentItemOption
    );

    const data = promoDish?.map((item: PromoProduct) => {
      return {
        id: item?.item_obj?.id,
        title: item?.item_obj?.name,
        description: item?.item_obj?.description,
        src: item?.item_obj?.src,
        price: calculatedBasket?.data?.presents[0]?.result_price_kop,
        actionId: calculatedBasket?.data?.presents[0]?.action,
      };
    });

    return data;
  }
);
/**
 * Returns a list of orders
 */
export const selectOrderHistoryList = (orderLabel: string) =>
  createSelector([selectOrderHistory], (orderHistory) => {
    if (orderHistory.length > 0) {
      return orderHistory.map((order) => {
        const date = new Date(order.date.split('/').reverse().join('/') || '')
          .toLocaleDateString()
          .split('T')[0];

        return {
          label: `${orderLabel} ${order.id} от ${date}`,
          value: order.id,
        };
      });
    }
    return [];
  });
export const selectCheckFreeDeliveryPrice = createSelector(
  [selectCheckDelivery],
  (checkDelivery) => {
    if (checkDelivery.data) {
      return checkDelivery.data.free_delivery_price;
    }
    return 0;
  }
);
/**
 * Returns result total price
 */
export const selectTotalPrice = createSelector(
  [selectOrderData],
  (orderData) => {
    const staticTotalPriceKop = orderData.basket.reduce(
      (sum, currentBasketItem) =>
        sum + currentBasketItem.total_price * currentBasketItem.count,
      0
    );
    const calculatedTotalPriceKop =
      orderData.calculatedBasket?.data?.total_price_kop;

    const totalPrice = formatPrice(
      calculatedTotalPriceKop || staticTotalPriceKop
    );

    return {
      totalPriceKop: calculatedTotalPriceKop || staticTotalPriceKop,
      totalPrice,
    };
  }
);
/**
 * Returns result reduslt total price
 */
export const selectResultTotalPrice = createSelector(
  [selectOrderData],
  (orderData) => {
    const staticPriceKop = orderData.basket.reduce(
      (sum, currentBasketItem) =>
        sum + currentBasketItem.total_price * currentBasketItem.count,
      0
    );
    const calculatedPriceKop =
      orderData.calculatedBasket?.data?.result_total_price_kop;

    const resultTotalPrice = formatPrice(calculatedPriceKop || staticPriceKop);

    return {
      resultTotalPriceKop: calculatedPriceKop || staticPriceKop,
      resultTotalPrice,
    };
  }
);
/**
 * Returns benefit price
 */
export const selectBenefitPrice = createSelector(
  [selectResultTotalPrice, selectTotalPrice, selectCalculatedBasket],
  (resultTotalPrice, totalPrice, calculatedBasket) => {
    const { resultTotalPriceKop } = resultTotalPrice;
    const { totalPriceKop } = totalPrice;
    const deliveryPrice = calculatedBasket?.data?.delivery_price;
    const benefitPriceKop = totalPriceKop - resultTotalPriceKop + deliveryPrice;

    const benefitPrice = formatPrice(benefitPriceKop || 0);

    return { benefitPriceKop, benefitPrice };
  }
);
/**
 * Returns delivery price
 */
export const selectDeliveryPrice = createSelector(
  [selectCalculatedBasket],
  (calculatedBasket) => {
    const deliveryPriceKop = calculatedBasket.data?.delivery_price;

    const deliveryPrice = formatPrice(deliveryPriceKop || 0);

    return { deliveryPriceKop, deliveryPrice };
  }
);
/**
 * Returns the remaining amount for free delivery
 */
export const selectPriceForFreeDelivery = createSelector(
  [selectTotalPrice, selectCheckFreeDeliveryPrice],
  (totalPrice, checkFreeDeliveryPrice) => {
    const { totalPriceKop } = totalPrice;
    const totalPriceConvert = totalPriceKop / 100;
    const freeDeliveryPriceKop = checkFreeDeliveryPrice - totalPriceConvert;

    const forFreeDeliveryPrice = formatPrice(freeDeliveryPriceKop * 100 || 0);

    return { freeDeliveryPriceKop, forFreeDeliveryPrice };
  }
);

export const selectSuccessDelivery = createSelector(
  [selectCheckDelivery],
  (checkDelivery) => checkDelivery.success
);
/**
 * Returns basket data
 */
export const selectBasketDataForRequest = createSelector(
  [selectBasket],
  (basketItems) =>
    basketItems.map((basketProduct) => {
      return {
        id: basketProduct.id,
        count: basketProduct.count,
        ingredients: basketProduct.ingredients,
        group_modifiers: basketProduct.group_modifiers,
        cooking_option_id: basketProduct.cooking_option_id || null,
      };
    })
);
/**
 * Returns the converted data for displaying the order on the payment page
 */
export const selectGeneratedOrder = createSelector(
  [selectBasket],
  (basketItems) =>
    basketItems.map((basketProduct) => {
      const totalPrice = formatPrice(basketProduct.total_price || 0);

      return {
        id: basketProduct.id,
        title: basketProduct.title,
        count: basketProduct.count,
        price: totalPrice,
      };
    })
);
/**
 * Returns total price for payment page
 */
export const selectOrderTotalPrice = createSelector(
  [selectResultTotalPrice, selectOrder],
  (resultTotalPrice, order) => {
    const { points } = order.payment;
    const { resultTotalPriceKop } = resultTotalPrice;

    const resultOrderTotalPrice = formatPrice(
      points ? resultTotalPriceKop - points : resultTotalPriceKop
    );

    return resultOrderTotalPrice;
  }
);
/**
 * Returns first available payment type or null if no type specified.
 * ToDo: maybe set default type at configuration type
 */
export const selectDefaultPaymentType = createSelector(
  [selectOrder, selectAppSettingsData],
  (order, settings) => {
    const isDelivery = order.delivery_type === 0;

    // Currently default delivery type is the first type available
    // Regarding delivery type
    if (isDelivery) {
      if (settings.PAYMENT_CARD) {
        return PAYMENT_TYPES.PAYMENT_CARD;
      }

      if (settings.PAYMENT_CARD_COURIER) {
        return PAYMENT_TYPES.PAYMENT_CARD_COURIER;
      }

      if (settings.PAYMENT_CASH) {
        return PAYMENT_TYPES.PAYMENT_CASH;
      }

      if (settings.PAYMENT_ON_ACCOUNT) {
        return PAYMENT_TYPES.PAYMENT_ON_ACCOUNT;
      }
    }

    // In case of self pick-up
    if (settings.PAYMENT_PICKUP_CARD) {
      return PAYMENT_TYPES.PAYMENT_CARD;
    }

    if (settings.PAYMENT_CARD_COURIER) {
      return PAYMENT_TYPES.PAYMENT_CARD_COURIER;
    }

    if (settings.PAYMENT_PICKUP_CASH) {
      return PAYMENT_TYPES.PAYMENT_CASH;
    }

    if (settings.PAYMENT_PICKUP_ON_ACCOUNT) {
      return PAYMENT_TYPES.PAYMENT_ON_ACCOUNT;
    }

    return null;
  }
);

export const selectPaymentTypeOptions = createSelector(
  [selectAppSettingsData, selectIsDelivery],
  (settings, isDelivery): PaymentTypeOption[] => {
    const paymentTypes: PaymentTypeOption[] = [];
    // In case of delivery method
    if (isDelivery) {
      if (settings.PAYMENT_CARD) {
        paymentTypes.push({
          label: settings.PAYMENT_TYPE_CARD_LABEL,
          value: PAYMENT_TYPES.PAYMENT_CARD,
        });
      }

      if (settings.PAYMENT_CARD_COURIER) {
        paymentTypes.push({
          label: settings.PAYMENT_CARD_COURIER_LABEL,
          value: PAYMENT_TYPES.PAYMENT_CARD_COURIER,
        });
      }

      if (settings.PAYMENT_CASH) {
        paymentTypes.push({
          label: settings.PAYMENT_CASH_LABEL,
          value: PAYMENT_TYPES.PAYMENT_CASH,
        });
      }

      if (settings.PAYMENT_ON_ACCOUNT) {
        paymentTypes.push({
          label: settings.PAYMENT_TYPE_ON_ACCOUNT_LABEL,
          value: PAYMENT_TYPES.PAYMENT_ON_ACCOUNT,
        });
      }

      return paymentTypes;
    }

    // In case of self pick-up
    if (settings.PAYMENT_PICKUP_CARD) {
      paymentTypes.push({
        label: settings.PAYMENT_TYPE_CARD_LABEL,
        value: PAYMENT_TYPES.PAYMENT_CARD,
      });
    }

    if (settings.PAYMENT_CARD_COURIER) {
      paymentTypes.push({
        label: settings.PAYMENT_CARD_COURIER_LABEL,
        value: PAYMENT_TYPES.PAYMENT_CARD_COURIER,
      });
    }

    if (settings.PAYMENT_PICKUP_CASH) {
      paymentTypes.push({
        label: settings.PAYMENT_CASH_LABEL,
        value: PAYMENT_TYPES.PAYMENT_CASH,
      });
    }

    if (settings.PAYMENT_PICKUP_ON_ACCOUNT) {
      paymentTypes.push({
        label: settings.PAYMENT_TYPE_ON_ACCOUNT_LABEL,
        value: PAYMENT_TYPES.PAYMENT_ON_ACCOUNT,
      });
    }

    return paymentTypes;
  }
);

export const selectTimedOrderScheduleData = createSelector(
  [selectOrderData],
  (orderData) => orderData.timedOrderSchedule.data
);

export const selectTimedOrderSchedule = createSelector(
  [selectTimedOrderScheduleData],
  (timedOrderScheduleData) => timedOrderScheduleData?.schedule
);

export const selectAsapInterval = createSelector(
  [selectTimedOrderScheduleData],
  (timedOrderScheduleData) => timedOrderScheduleData?.asap_bounds
);

export const selectPickupTime = createSelector(
  [selectOrder],
  (order) => order.pickup_time
);
