import { useCallback, useMemo, useState, useEffect } from 'react';
import { useAuth } from 'hooks/auth';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { updateAccountData } from 'store/reducers/AccountSlice/ActionCreators';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import {
  selectCalculatedBasket,
  selectBasket,
  selectBasketRecommendations,
  selectCalculatedBasketResponse,
  selectCutlery,
  selectIsDelivery,
  selectOrder,
  selectPickupTime,
} from 'store/selectors/orderSelectors';
import { selectApiKey } from 'store/selectors/accountSelectors';
import { accountSlice } from 'store/reducers/AccountSlice/AccountSlice';
import {
  checkDelivery,
  getTimedOrderSchedule,
} from 'store/reducers/OrderSlice/ActionCreators';
import {
  selectAddressForBasket,
  selectAddressById,
} from 'store/selectors/addressSelectors';
import { Address } from 'types/Address';
import { MenuProduct } from 'types/Menu';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { useSnackbar } from 'notistack';
import { SnackActions } from 'components/generic';
import { useTranslation } from 'react-i18next';
import { AppSettingsData } from 'types/AppSettings';
import { useWorkTime } from 'hooks/basket';
import { selectCityId } from 'store/selectors/citySelectors';

const useBasket = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const {
    watch,
    setError,
    formState: { isValid },
    handleSubmit,
  } = methods;
  const {
    IS_CUTLERY_REQUIRED,
    MESSAGE_INFO_TYPE,
    MESSAGE_INFO_TEXT,
    ACTION_PROMOCODE,
  } = useAppSelector<AppSettingsData>(selectAppSettingsData);
  const deliveryAddressId = watch('place');
  const restaurantId = watch('restaurant');
  const { isAuth } = useAuth();
  const { clearAuthResponse } = accountSlice.actions;
  const api_key = useAppSelector<string>(selectApiKey);
  const cutleryCount = useAppSelector<number>(selectCutlery);
  const [authModal, setAuthModal] = useState<boolean>(false);
  const [addressModal, setAddressModal] = useState<boolean>(false);
  const products = useAppSelector<MenuProduct[]>(selectBasketRecommendations);
  const deliveryAddress = useAppSelector<Address | undefined>(
    selectAddressById(deliveryAddressId)
  );
  const { message, success } = useAppSelector(selectCalculatedBasketResponse);
  const selectedAddressData = useAppSelector(selectAddressForBasket);
  const {
    addAddress,
    removeRestaurant,
    removeAddress,
    addRestaurant,
    removeProductById,
  } = orderSlice.actions;
  const isDelivery = useAppSelector<boolean>(selectIsDelivery);
  const [firstCheckCutlery, setFirstCheckCutlery] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const isWorkTime = useWorkTime();
  const pickupTime = useAppSelector(selectPickupTime);
  const basketItems = useAppSelector(selectBasket);
  const calculatedBasket = useAppSelector(selectCalculatedBasket);

  const handleAuthModalOpen = useCallback(() => {
    setAuthModal(true);
  }, [authModal]);

  const handleAuthModalClose = useCallback(() => {
    setAuthModal(false);
    dispatch(clearAuthResponse());
  }, [authModal]);

  const handleAddressModalOpen = useCallback(() => {
    setAddressModal(true);
  }, [addressModal]);

  const handleAddressModalClose = useCallback(() => {
    setAddressModal(false);
  }, [addressModal]);

  const handleAddressButton = useMemo(() => {
    if (isAuth) {
      return handleAddressModalOpen;
    }
    return handleAuthModalOpen;
  }, [isAuth]);

  useEffect(() => {
    if (deliveryAddress) {
      dispatch(
        checkDelivery({
          api_key,
          body: {
            city_id: deliveryAddress.city,
            street: deliveryAddress.street,
            house: deliveryAddress.house,
          },
        })
      );
    }
  }, [deliveryAddress]);

  const onSubmit = (formData: any) => {
    if (cutleryCount === 0 && IS_CUTLERY_REQUIRED && !firstCheckCutlery) {
      enqueueSnackbar(t('please_specify_the_number_of_cutlery'), {
        action: SnackActions,
        variant: 'warning',
        autoHideDuration: 3000,
        preventDuplicate: true,
      });
      setFirstCheckCutlery(true);
    } else {
      dispatch(
        updateAccountData({
          api_key,
          body: {
            first_name: formData.name,
            phone: formData.phone,
          },
        })
      )
        .unwrap()
        .then((res) => {
          if (res.success) {
            navigate('/payment');
          } else if (res.reason === 'PHONE_ALREADY_USE') {
            setError('phone', {
              type: 'server',
            });
            enqueueSnackbar(t('phone_already_use'), {
              action: SnackActions,
              variant: 'error',
              autoHideDuration: 3000,
              preventDuplicate: true,
            });
          }
        });
    }
  };

  const handleCheckoutButton = () => {
    if (
      isAuth &&
      isValid &&
      (isWorkTime.isOk || (isWorkTime.isErr && pickupTime))
    ) {
      handleSubmit(onSubmit)();
    }

    if (isAuth && isWorkTime.isErr) {
      let closeMessage: string;
      if (isWorkTime.value.reason === 'TOO_SOON') {
        closeMessage = `Мы пока закрыты. Откроемся в ${isWorkTime.value.opening_time
          .local()
          .format('HH:mm')}.`;
      } else if (isWorkTime.value.reason === 'TOO_LATE') {
        closeMessage = `Мы уже закрыты.`;
      } else {
        closeMessage = 'Возникла ошибка';
      }

      enqueueSnackbar(closeMessage, {
        action: SnackActions,
        variant: 'info',
        autoHideDuration: 6000,
        preventDuplicate: true,
      });
    }

    if (isAuth && !isValid) {
      enqueueSnackbar(t('please_fill_in_the_required_fields'), {
        action: SnackActions,
        variant: 'error',
        autoHideDuration: 3000,
        preventDuplicate: true,
      });
    }

    if (!isAuth) {
      handleAuthModalOpen();
    }
  };

  useEffect(() => {
    if (MESSAGE_INFO_TYPE === 1 || MESSAGE_INFO_TYPE === 2) {
      enqueueSnackbar(MESSAGE_INFO_TEXT, {
        action: SnackActions,
        variant: 'info',
        autoHideDuration: 10000,
      });
    }
  }, [MESSAGE_INFO_TYPE]);

  useEffect(() => {
    if (!success && message) {
      enqueueSnackbar(message, {
        action: SnackActions,
        variant: 'error',
        autoHideDuration: 10000,
      });
    }
  }, [message, success]);

  useEffect(() => {
    if (isDelivery) {
      dispatch(addAddress(selectedAddressData));
      dispatch(removeRestaurant());
    } else {
      dispatch(removeAddress());
      dispatch(addRestaurant(Number(restaurantId)));
    }
  }, [selectedAddressData, isDelivery]);

  useEffect(() => {
    const isPickupItem = basketItems.some(
      (item) => item.is_only_pickup || item.is_only_pickup_category
    );

    if (isPickupItem) {
      enqueueSnackbar(t('only_pickup_message'), {
        action: SnackActions,
        variant: 'info',
        autoHideDuration: 10000,
        preventDuplicate: true,
      });
    }
  }, []);

  useEffect(function removeIllegalItems() {
    if (
      calculatedBasket.success ||
      calculatedBasket.reason !== 'HAS_ILLEGAL_ITEMS' ||
      calculatedBasket.data.illegal_item_option_ids === undefined
    ) {
      return;
    }

    calculatedBasket.data.illegal_item_option_ids.forEach((itemId) =>
      dispatch(removeProductById(itemId))
    );

    enqueueSnackbar(t('basket.notifications.illegal_items_removed'), {
      action: SnackActions,
      variant: 'info',
      autoHideDuration: 10000,
    });
  }, []);

  const cityId = useAppSelector(selectCityId);
  const order = useAppSelector(selectOrder);

  function pullTimedOrderSchedule() {
    if (!cityId) {
      return;
    }

    const args = {
      ...(!(isDelivery && order.address !== null) && {
        city_id: cityId,
      }),
      ...(isDelivery && order.address !== null && { address: order.address }),
    };

    dispatch(getTimedOrderSchedule(args));
  }

  useEffect(() => {
    const timer = setInterval(() => {
      pullTimedOrderSchedule();
    }, 5 * 60 * 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    pullTimedOrderSchedule();
  }, [cityId, isDelivery, order.address]);

  return {
    onSubmit,
    methods,
    authModal,
    handleAuthModalClose,
    addressModal,
    handleAddressModalClose,
    handleAddressButton,
    handleCheckoutButton,
    products,
    isPromocode: ACTION_PROMOCODE,
  };
};

export default useBasket;
